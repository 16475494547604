import $ from "jquery";
import Splide from "@splidejs/splide";
import Alpine from "alpinejs";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

let splideReviewsInstance;
const splideInitializer = function () {
  if ($(".splide-reviews").length > 0) {
    if (splideReviewsInstance) {
      splideReviewsInstance.destroy();
    }

    splideReviewsInstance = new Splide(".splide-reviews", {
      type: "loop",
      drag: true,
      arrows: false,
      pagination: false,
      focus: "center",
      gap: 24,
      autoWidth: true,
      autoScroll: {
        speed: 0.75,
        pauseOnHover: true,
        pauseOnFocus: true,
      },
    }).mount({ AutoScroll });
  }

  if ($(".splide-grads").length > 0) {
    var gradSplide = new Splide(".splide-grads", {
      type: "loop",
      drag: true,
      arrows: true,
      pagination: true,
      gap: 24,
      perPage: 3,
      classes: {
        pagination: "splide__pagination pt-5 md:pt-6 lg:pt-8",
        page: "splide__pagination__page rounded-full transition-colors duration-200 ease-out hover:bg-green-600 w-2.5 h-2.5 bg-gray-400 mx-1",
      },
      breakpoints: {
        680: {
          perPage: 1,
        },
        1023: {
          arrows: false,
        },
        1260: {
          perPage: 2,
        },
      },
    });
    gradSplide.on("mounted", function () {
      // if fewer slides than provided in options, set option to the number of slides
      if (gradSplide.length <= gradSplide.options.perPage) {
        gradSplide.options.perPage = gradSplide.length;
      }
    });
    gradSplide.mount();
  }

  if ($(".splide-leaders").length > 0) {
    new Splide(".splide-leaders", {
      perPage: 3,
      gap: 16,
      type: "loop",
      breakpoints: {
        1024: {
          perPage: 2,
          gap: 12,
        },
        640: {
          perPage: 1,
          gap: 10,
        },
      },
    }).mount();
  }

  if ($(".splide-tab-multiple").length > 0) {
    document.querySelectorAll(".splide-tab-multiple").forEach((element) => {
      var gradSplide = new Splide(element, {
        type: "loop",
        drag: true,
        arrows: true,
        pagination: false,
        gap: 24,
        perPage: 3,
        classes: {
          pagination: "splide__pagination pt-5 md:pt-6 lg:pt-8",
          page: "splide__pagination__page rounded-full transition-colors duration-200 ease-out hover:bg-green-600 w-2.5 h-2.5 bg-gray-400 mx-1",
        },
        breakpoints: {
          680: {
            perPage: 1,
          },
          1260: {
            perPage: 2,
          },
        },
      });

      gradSplide.on("mounted", function () {
        // Adjust the perPage option if there are fewer slides
        if (gradSplide.length <= gradSplide.options.perPage) {
          gradSplide.options.perPage = gradSplide.length;
        }
      });

      gradSplide.mount();
    });
  }

  if ($(".splide-grads-single").length > 0) {
    new Splide(".splide-grads-single", {
      perPage: 1,
      gap: 50,
    }).mount();
  }

  if ($(".splide-partners").length > 0) {
    new Splide(".splide-partners", {
      perPage: 2,
      gap: 16,
      type: "loop",
      breakpoints: {
        640: {
          perPage: 1,
          gap: 10,
        },
      },
    }).mount();
  }

  if ($(".splide-auto-left").length > 0) {
    new Splide(".splide-auto-left", {
      type: "loop",
      drag: true,
      arrows: false,
      pagination: false,
      focus: "center",
      gap: 24,
      autoWidth: true,
      autoScroll: {
        speed: 0.5,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
    }).mount({ AutoScroll });
  }

  if ($(".splide-auto-right").length > 0) {
    new Splide(".splide-auto-right", {
      type: "loop",
      direction: "rtl",
      drag: true,
      arrows: false,
      pagination: false,
      focus: "center",
      gap: 24,
      autoWidth: true,
      autoScroll: {
        speed: 0.5,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
    }).mount({ AutoScroll });
  }

  if ($(".splide-timeline-v1").length > 0) {
    new Splide(".splide-timeline-v1", {
      autoWidth: true,
      focus: 0,
      omitEnd: true,
      gap: 75,
      breakpoints: {
        1280: {
          gap: 50,
        },
        768: {
          gap: 30,
        },
      },
    }).mount();
  }
};

let splideTickerInstance;

const enrolledSplideInitializer = function (ev) {
  targetElement = ev ? ev.target : document;
  const splideTickers = targetElement.querySelectorAll(".splide-ticker");
  if (splideTickers.length > 0) {
    if (splideTickerInstance) {
      splideTickerInstance.destroy();
    }

    splideTickerInstance = new Splide(".splide-ticker", {
      type: "loop",
      drag: false,
      arrows: false,
      pagination: false,
      focus: "center",
      gap: 36,
      autoWidth: true,
      autoScroll: {
        speed: 0.6,
        pauseOnHover: true,
        pauseOnFocus: true,
      },
    }).mount({ AutoScroll });
  }

  if (targetElement.querySelectorAll(".splide-timeline").length > 0) {
    const perPage = Math.min(
      parseInt(document.getElementById("session-info").dataset.coursesCount),
      15
    );
    const splideTimeline = new Splide(".splide-timeline", {
      type: "slide",
      drag: true,
      arrows: true,
      pagination: false,
      perMove: 1,

      // This perPage == number of sessions
      // Max perPage value is 19, if there are more than 19 sessions, perPage will still be 19
      perPage: perPage,
      omitEnd: true,
      breakpoints: {
        450: {
          // This perPage == number of sessions. Max perPage value is 6
          perPage: 6,
        },
        640: {
          // This perPage == number of sessions. Max perPage value is 8
          perPage: 8,
        },
        1150: {
          // This perPage == number of sessions. Max perPage value is 13
          perPage: 13,
        },
      },
    }).mount();

    // selected session - 2
    // On this page, we're on session 3, so below == 1
    // -1 to account for index & -1 so the previous session is visible in the slider on smaller screens
    let timelineNumber = parseInt(
      document.getElementById("session-info").dataset.timeline
    );
    splideTimeline.go(timelineNumber);
  }

  if (targetElement.querySelectorAll(".splide-mentors").length > 0) {
    const splideMentors = new Splide(".splide-mentors", {
      type: "slide",
      drag: true,
      arrows: true,
      pagination: true,
      perPage: 4,
      classes: {
        pagination: "splide__pagination pt-5 md:pt-6 lg:pt-8",
        page: "splide__pagination__page rounded-full transition-colors duration-200 ease-out hover:bg-acai-600 w-2.5 h-2.5 bg-gray-400 mx-1",
      },
      breakpoints: {
        639: {
          perPage: 1,
        },
        1023: {
          perPage: 2,
        },
        1279: {
          perPage: 3,
        },
      },
    });

    splideMentors.mount();

    // Center slides when there's less than 4 slides
    // justify-center breaks the slider, so we're only applying this to breakpoints where splideMentors.length < perPage
    // splideMentors.length = number of slides
    var splideMentorsList = document.getElementById("splide-mentors-list");
    if (splideMentors.length == 3) {
      splideMentorsList.classList.add("xl:justify-center");
    }
    if (splideMentors.length == 2) {
      splideMentorsList.classList.add("lg:justify-center");
    }
    if (splideMentors.length == 1) {
      splideMentorsList.classList.add("sm:justify-center");
    }
  }

  if (targetElement.querySelectorAll(".splide-enrolled-mentors").length > 0) {
    const splideEnrolledMentors = new Splide(".splide-enrolled-mentors", {
      type: "slide",
      drag: true,
      arrows: true,
      pagination: true,
      perPage: 3,
      classes: {
        pagination: "splide__pagination pt-5 md:pt-6 lg:pt-8",
        page: "splide__pagination__page rounded-full transition-colors duration-200 ease-out hover:bg-acai-600 w-2.5 h-2.5 bg-gray-400 mx-1",
      },
      breakpoints: {
        525: {
          perPage: 1,
        },
        1280: {
          perPage: 2,
        },
      },
    });

    splideEnrolledMentors.mount();

    // Center slides when there's less than 3 slides
    // justify-center breaks the slider, so we're only applying this to breakpoints where splideEnrolledMentors.length < perPage
    // splideEnrolledMentors.length = number of slides
    var splideEnrolledMentorsList = document.getElementById(
      "splide-enrolled-mentors-list"
    );
    if (splideEnrolledMentors.length == 2) {
      splideEnrolledMentorsList.classList.add("lg:justify-center");
    }
    if (splideEnrolledMentors.length == 1) {
      splideEnrolledMentorsList.classList.add("sm:justify-center");
    }
  }
};

const organizationSplideInitializer = function () {
  if ($(".splide-benefits").length > 0) {
    var startingSlide = 1;
    var splideBenefits = new Splide(".splide-benefits", {
      drag: true,
      arrows: false,
      pagination: false,
      focus: "center",
      trimSpace: false,
      gap: 36,
      start: startingSlide,
      autoWidth: true,
      breakpoints: {
        640: {
          gap: 24,
        },
      },
    }).mount();
    var prevSplideBenefitsSlide = startingSlide;
    // Change slider position when slide is clicked
    splideBenefits.on("click", function (s, e) {
      splideBenefits.go(s.index);
      prevSplideBenefitsSlide = s.index;
    });
    // Change alpine data when slider active slide changes (when dragged)
    splideBenefits.on("active", function (s) {
      if (prevSplideBenefitsSlide != s.index) {
        prevSplideBenefitsSlide = s.index;
        Alpine.store("benefits").activeTab = s.index;
      }
    });
    Alpine.store("benefits", {
      activeTab: startingSlide,
      switchTab(tab) {
        this.activeTab = tab;
      },
    });
  }
};

document.addEventListener("DOMContentLoaded", organizationSplideInitializer);
document.addEventListener("turbo:load", organizationSplideInitializer);
window.addDOMContentOrTurboLoadedEventListener(enrolledSplideInitializer);
window.addDOMContentOrTurboLoadedEventListener(splideInitializer);
window.addDOMContentOrTurboLoadedEventListener(splideInitializer, "turbo:load");
window.addDOMContentOrTurboLoadedEventListener(
  enrolledSplideInitializer,
  "turbo:frame-load"
);
